import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import HomePage from "./Pages/HomePage";
import TourServices from "./Pages/TourServices";
import TourHighlights from "./Components/Highlights/Highlights";
import TourContact from "./Pages/TourContact";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/Services" element={<TourServices />} />
          <Route path="/tourHighlights" element={<TourHighlights />} />
          <Route path="/contactUs" element={<TourContact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
