import React from 'react';
import Services from '../Components/Services/Services';


const TourServices = () => {
    return(
        <div>
            <Services/>
        </div>
    )
}
export default TourServices