import React, { useState } from 'react';
import { Button, Drawer,Col, Row } from 'antd';
import './Navbar.css'
import { Link } from 'react-router-dom';
import logo from '../Assets/D-icon3.png'
import '@fortawesome/fontawesome-free/css/all.css';


const Navbar = () => {
    const [menu, setMenu] = useState("shop");
    const [open, setOpen] = useState(false);
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    return(
    <Row className='navbar' gutter={[16, 16]}>
      <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={3}>
        <div className='nav-logo' style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <img src={logo} alt="" className="FBT logo" />
          <p style={{ display: 'inline-block', margin: '0' }}><b>FBT</b></p>
        </div>
      </Col>

      <Col xs={0} sm={12} md={16} lg={18} xl={20} xxl={21}>
        <div className="mobileHidden" targetOffset="60">
          <ul className='nav-menu' style={{ display: 'flex', alignItems: 'right' }}>
            <li onClick={() => { setMenu("aboutUs") }}>
              <Link style={{ textDecoration: 'none' }} to='/'>About Us</Link>
              {menu === "aboutUs" ? <hr /> : <></>}
            </li>
            <li onClick={() => { setMenu("services") }}>
              <Link style={{ textDecoration: 'none' }} to='/services'>Services</Link>
              {menu === "services" ? <hr /> : <></>}
            </li>
            <li onClick={() => { setMenu("tourHighlights") }}>
              <Link style={{ textDecoration: 'none' }} to='/tourHighlights'>Tour Highlights</Link>
              {menu === "tourHighlights" ? <hr /> : <></>}
            </li>
            <li onClick={() => { setMenu("contactUs") }}>
              <Link style={{ textDecoration: 'none' }} to='/contactUs'>Contact Us</Link>
              {menu === "contactUs" ? <hr /> : <></>}
            </li>
          </ul>
        </div>
      </Col>

      <Col xs={24} sm={12} md={8} lg={6} xl={4} xxl={0} >
        <div className="mobileVisible" >
          <Button type="primary" onClick={showDrawer}style={{ display: 'flex',justifyContent: 'flex-end', alignItems: 'center' }} >
            <i className='fas fa-bars' />
          </Button>
          <Drawer title="Menu" onClose={onClose} open={open} placement="right" width="280px">
            
              <li onClick={() => { setMenu("aboutUs"); onClose(); }}>
                <Link style={{ textDecoration: 'none' }} to='/'>About Us</Link>
                {menu === "aboutUs" ? <hr /> : <></>}
              </li>
              <li onClick={() => { setMenu("services"); onClose(); }}>
                <Link style={{ textDecoration: 'none' }} to='/services'>Services</Link>
                {menu === "services" ? <hr /> : <></>}
              </li>
              <li onClick={() => { setMenu("tourHighlights"); onClose(); }}>
                <Link style={{ textDecoration: 'none' }} to='/tourHighlights'>Tour Highlights</Link>
                {menu === "tourHighlights" ? <hr /> : <></>}
              </li>
              <li onClick={() => { setMenu("contactUs"); onClose(); }}>
                <Link style={{ textDecoration: 'none' }} to='/contactUs'>Contact Us</Link>
                {menu === "contactUs" ? <hr /> : <></>}
              </li>
            
          </Drawer>
        </div>
      </Col>
    </Row>
    )
}

export default Navbar