    import React from 'react';
    import './Footer.css'
    import { Link } from 'react-router-dom';
    import instagram_icon from '../Assets/instagram_icon1.jpeg'
    import facebook_icon from '../Assets/facebook_icon.png'
    import x_icon from '../Assets/x.png'
    import linkedin_icon from '../Assets/linkedIn.png'

    const Footer = () => {
        return(
            <div className='footer'>
                <div className="footer-logo">
                    <p>FLAMINGO BOAT TOURS</p>
                    <ul className='footer-links'>
                        <li><Link to="/" className="white-text"> About</Link></li>
                        <li><Link to="/contactUs" className="white-text"> Contact</Link></li>
                        <li>Let's connect!</li>
                    </ul>
                    <div className="footer-social-icons">
                        <div className="footer-icons-container">
                            <a href="https://www.instagram.com/flamingo_tours_us?igsh=OGQ5ZDc2ODk2ZA%3D%3D" target="_blank" rel="noopener noreferrer" >
                            <img src={instagram_icon} alt="" />
                            </a>
                        </div>
                        <div className="footer-icons-container">
                            <a href="https://www.facebook.com/flamingo.us.boat.tours?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer" >
                            <img src={facebook_icon} alt="Facebook" />
                            </a>
                        </div>
                        <div className="footer-icons-container">
                            <a href="https://twitter.com/flamingotoursus?s=11&t=nzB8kwsSgAzxMjyZa7_wog" target="_blank" rel="noopener noreferrer" >
                            <img src={x_icon} alt="X" />
                            </a>
                        </div>
                        <div className="footer-icons-container">
                            <a href="https://www.linkedin.com/company/flamingoboattours/about/" target="_blank" rel="noopener noreferrer" >
                            <img src={linkedin_icon} alt="X" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="footer-copyright">
                    <hr />
                    <p>Copyright @ 2024 - All rights are reserved.</p>
                </div>
            </div>
        )
    }
    export default Footer