import React from 'react';
import './Home.css'
import icon1 from '../Assets/D-icon1.png'
import icon2 from '../Assets/D-icon2.png'
import ImageSlide from './ImageSlider'

const Home = () => {



    return(
        <div className='home'>
            <div className="home-boat" >
               <p5>Welcome to Coastal Escapes – Your Gateway to Unforgettable Water Adventures!</p5>
            </div>  
            <div className="about">
               <ImageSlide/>
                <p>Why Flamingo Boat Tours?</p>
                <p1>At Coastal Escapes, we're passionate about curating exceptional water experiences, and our pride lies in our unique board tours. Nestled in the heart of Long Beach, California, we invite you to embark on a journey that combines the tranquility of the Naples Canal with the vibrant energy of the Long Beach coast.</p1>

                <img src={icon1} alt="" />
                <div className="" style={{ borderBottom: '2px solid #ccc', paddingBottom: '10px', margin:'0px' }}> </div>

                

                <p2>Our mission is to provide a haven for those seeking a perfect blend of relaxation, breathtaking scenery, and indulgence. Coastal Escapes promises an immersive escape where every moment is designed to create lasting memories.</p2>

                <img src={icon2} alt="" />

                <p6>Join us, and let Coastal Escapes be the canvas for your next aquatic adventure!..</p6>

                <p3>*Book Your Unique Board Tour Today:*</p3>
                <p4>Don't miss the opportunity to create memories that will last a lifetime. Book your Unique Board Tour with Coastal Escapes and let the adventure begin!</p4>
            </div>
        </div>
        
        
    )
}

export default Home