import React, { useEffect, useState } from 'react';
import './ContactUs.css'
// import icon1 from '../Assets/D-icon1.png'


const ContactUs = () => {
    const [emailValue, setEmailValue] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [contactValue, setContactValue] = useState('');
  const [messageValue, setMessageValue] = useState('');

    useEffect(() => {
    // Load smtp.js script dynamically
    const script = document.createElement('script');
    script.src = 'https://smtpjs.com/v3/smtp.js';
    script.async = true;

    script.onload = () => {
      // The script has loaded, you can use the smtp object from window.Email here
      // console.log('smtp.js has loaded');
    };

    document.body.appendChild(script);

    // Clean up on component unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleFormSubmit = (e) => {
  e.preventDefault();

    // Example: Send email using smtp.js
    window.Email.send({
      Host : "smtp.elasticemail.com",
      Username : "supriya.bandal2012@gmail.com",
      Password : "A41874A98F7C78256C0093ACF501C4C99AA2",
      To: 'sangrambusiness1@gmail.com',
      From: emailValue, 
      Subject: 'Flamingo Boat contact',
      Body: `Name: ${nameValue}<br>Email: ${emailValue}<br>Contact: ${contactValue}<br>Message: ${messageValue}`,
    }).then(
      message => {
      // console.log(message);
      alert('Thank You for Contacting us.');

      setEmailValue('');
        setNameValue('');
        setContactValue('');
        setMessageValue('');
    },
    error => {
      console.error(error);
      alert('Email sending failed');
    }
    );
  };



    return(
        <div className='contact'>
            <form onSubmit={handleFormSubmit} action="" className="">
                <h3>Contact Us</h3>
                <p>We will get back to you asap!</p>

                <input type="text" id='name' placeholder='Your Name' required value={nameValue}
          onChange={(e) => setNameValue(e.target.value)}/>

                <input type="email" id='email' placeholder='Email id' required value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}/>

                <input type="text" id='phone' placeholder='Phone Number' required value={contactValue}
          onChange={(e) => setContactValue(e.target.value)}/>

                <textarea name="Message" id="message"  rows="4" placeholder='How can we help you?' value={messageValue}
          onChange={(e) => setMessageValue(e.target.value)}></textarea>
          
                <button type='submit'>Send</button>
            </form>
        </div>
        
        
        
    )
}

export default ContactUs