import React from 'react';
import ContactUs from '../Components/ContactUs/ContactUs';


const TourContact = () => {
    return(
        <div>
            <ContactUs/>
        </div>
    )
}
export default TourContact