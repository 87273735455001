import React from 'react';
import './Services.css'
import icon1 from '../Assets/D-icon1.png'


const Services = () => {
    return(
        <div className="services">
            <p3 >Unique Board Tour</p3>
            <div className='services-image'></div>
            
            <p1>Explore the Magic of Long Beach Waters: A Three-Hour Journey</p1>

            <p2> Experience the beauty of Long Beach like never before with our Unique Board Tour. This exclusive three-hour excursion takes you through the scenic Naples Canal and around the captivating coastline of Long Beach.</p2>

            <img src={icon1} alt="" style={{ borderBottom: '2px solid #ccc', paddingBottom: '10px', margin:'0px' }}/>
           
        </div>
    )
}
export default Services