import React from 'react'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import slide_image_1 from '../Assets/ImageSlider/img_1.jpg';
import slide_image_2 from '../Assets/ImageSlider/img_2.jpg';
import slide_image_4 from '../Assets/ImageSlider/img_4.jpg';
import slide_image_5 from '../Assets/ImageSlider/img_5.jpg';
import slide_image_6 from '../Assets/ImageSlider/img_6.jpg';
import slide_image_7 from '../Assets/ImageSlider/img_7.jpg';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import './ImageSlider.css';


const ImageSlider = () => {
  return (
    <div>
      <Swiper
      spaceBetween={50}
      slidesPerView={3}
      breakpoints={{
          // when window width is >= 430px
          300: {
            slidesPerView: 1,
          },
          430: {
            slidesPerView: 1,
          },
          640: {
            slidesPerView: 2,
          },
          825: {
            slidesPerView: 3,
          },
        }}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
      onSwiper={(swiper) => console.log(swiper)}
      onSlideChange={() => console.log('slide change')}
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        
        coverflowEffect={{
          rotate: 50,
          stretch: -100,
          depth: 500,
          modifier: 1,
          slideShadows: true,
        }}
    
        modules={[Navigation, Pagination, Scrollbar, A11y]}
        className="mySwiper"
        autoplay={{ delay: 6000, disableOnInteraction: false }}
      >
        <SwiperSlide>
          <img src={slide_image_7} alt="slide_image" />
        </SwiperSlide>
        
        <SwiperSlide>
          <img src={slide_image_6} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_1} alt="slide_image" />
        </SwiperSlide>
         <SwiperSlide>
          <img src={slide_image_5} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide_image_4} alt="slide_image" />
        </SwiperSlide>
               
        
       
      </Swiper>
    </div>
  )
}

export default ImageSlider
