import React from 'react';
import './Highlights.css'
import icon1 from '../Assets/D-icon1.png'
import Img1 from '../Assets/snack.png'
import Img2 from '../Assets/wine.png'
import Img3 from '../Assets/music.png'
import Img4 from '../Assets/sunset.png'


const Highlights = () => {
    return(
        <div className="highlights">
            <p2>Our Tour Highlights</p2>

            <div className="container">
                <div className="box" id='b1'>
                    <img src={Img1} alt="Icon 1" className="circle-icon" />
                    
                        <h2>Complimentary Snacks</h2>
                        <p>Indulge your taste buds with a selection of carefully curated complimentary snacks. Our goal is to ensure your journey is not only visually stunning but a delight for your senses.</p>
                </div>

                <div className="box" id='b2'>
                    <img src={Img2} alt="Icon 1" className="circle-icon" />
                    <h2>Optional Wine Package</h2>
                    <p>Elevate your experience with our optional wine package. Sip on fine wines as you cruise through the charming canals and bask in the awe-inspiring views.</p>
                </div>

                <div className="box" id='b3'>
                    <img src={Img3} alt="Icon 1" className="circle-icon" />
                    <h2>The Musical Serenade</h2>
                    <p>Let the rhythm of the ocean be your soundtrack. Enjoy a carefully crafted music playlist, adding a harmonious touch to your journey.</p>
                </div>

                <div className="box" id='b4'>
                    <img src={Img4} alt="Icon 1" className="circle-icon" />
                    <h2>Southern California Sunset Spectacle</h2>
                    <p>Witness the breathtaking Southern California sunset as it paints the sky in hues of orange, pink, and gold. Our tours are timed perfectly to capture this natural spectacle.</p>
                </div>
            </div>

            <p1>Embark on a Coastal Escape and let the beauty of Long Beach be the backdrop to your unforgettable journey.</p1>
            <img src={icon1} alt="" style={{ borderBottom: '2px solid #ccc', paddingBottom: '10px', margin:'0px' }}/>
        </div>
    );
};
export default Highlights